<template>
  <div class="container home">
    <div class="content">
      <div class="heading">
        <h1>Scrawl</h1>
      </div>
      <div class="cta">
        <a href="#login" class="log-in">Login</a>
        <a href="#signup" class="sign-up">Sign Up</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home {
  .content {
    background-image: linear-gradient(-45deg, #27adf2 0, #48ebe2 100%);
  }

  text-align: center;
  .heading {
    margin-top: 40%;
    font-family: "Twinkle Star", cursive;
    font-size: 2.5em;
    transform: rotate(325deg);

    h1 {
      color: white;
    }
    .pen {
      animation-duration: 3s;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .cta {
    position: absolute;
    bottom: 10%;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    padding-top: 20px;

    .log-in {
      width: 90%;
      font-size: 1.2em;
      padding: 15px;
      background-color: white;
      border-radius: 4px;
      border-style: none;
      box-sizing: border-box;
      color: $primary-color;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      line-height: 1.5;
      margin: 0;
      outline: none;
      overflow: hidden;
      text-align: center;
      text-transform: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }

    .sign-up {
      font-size: 0.8em;
      display: block;
      padding-top: 5%;
      color: white;
      font-size: 1.3em;
    }
  }
}
</style>
