<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="search">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import Parse from "parse";

export default {
  name: "app",
  created() {
    Parse.initialize("scrawl");
    // eslint-disable-next-line
    Parse.serverURL = process.env.VUE_APP_API;
  },
};
</script>

<style lang="scss">
#app {
  left: 0;
  right: 0;
  height: 100vh;
  position: fixed;
}
</style>
