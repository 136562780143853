import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home";
import Parse from "parse";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/About"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  },
  {
    path: "/signup",
    name: "SignUp",
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp"),
  },
  {
    path: "/feed",
    name: "feed",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "feed" */ "../views/Feed"),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "search" */ "../views/Search"),
  },
  {
    path: "/reply/:id/:from",
    name: "reply",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "reply" */ "../views/Reply"),
  },
  {
    path: "/post/:from",
    name: "post",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "post" */ "../views/Post"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuth = Parse.User.current();

  if (
    to.matched.some((record) => {
      if (record.meta.requiresAuth) {
        //Route needs auth
        //Check if the user is logged in
        if (isAuth) {
          next();
        } else {
          //Send user to home page
          next("/");
        }
      } else {
        if (isAuth) {
          //User is logged in but trying to access non secure page
          next("/feed");
        } else {
          next();
        }
      }
    })
  );
});

export default router;
